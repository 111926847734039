<template>
  <div class="t_header">
    <div class="login">
      <div class="f-h-c">
        <div class="f-bc head">
          <div>
            <img :src="logo" class="logo" />
          </div>
          <router-link to="/">
            <div>网站首页</div>
          </router-link>
        </div>
      </div>
      <div class="f-h-c main-box">
        <div class="main">
          <div v-if="isShowAgreement == false">
            <div class="popup_login_box" v-on:click.stop v-if="isType == '1' || isType == '2' || isType == '3'">
              <el-tabs @tab-click="handleClick" v-model="activeName" class="popup_login_box_tabs">
                <el-tab-pane label="微信登录" name="1">
                  <div class="popup_login_box_tabs_one">
                    <p class="popup_login_box_tabs_one_text">
                      <img src="@/assets/home/WX_icon.png" alt />
                      <span>请使用微信扫一扫登录</span>
                    </p>
                    <!-- <div class="block" v-if="checked==false" style="width:170px;height:170px;">
                    <el-image>
                      <div slot="error" class="image-slot" >
                        请阅读并同意<br/>《用户服务协议》<br/>《隐私政策》
                      </div>
                    </el-image>
                    </div>-->
                    <p class="popup_login_box_tabs_one_codeImg" v-loading="!qrCodeUrl">
                      <img :src="qrCodeUrl" alt v-if="qrCodeUrl" />
                    </p>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="账号登录" name="2">
                  <div class="popup_login_box_tabs_two">
                    <div class="popup_login_box_tabs_two_form">
                      <p class="popup_login_box_tabs_two_item">
                        <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" v-model="account" maxlength="11"></el-input>
                      </p>
                      <p class="popup_login_box_tabs_two_item">
                        <el-input prefix-icon="el-icon-key" placeholder="请输入密码" v-model="password" autocomplete="new-password" maxlength="32"></el-input>
                      </p>
                      <p class="popup_login_box_tabs_two_other">
                        <span style="cursor:pointer;flex:auto;text-align:right;" @click="wangjimima()">忘记密码?</span>
                      </p>
                      <el-button type="primary" class="btn_login" @click="login(1)" v-if="checked == true">登录</el-button>
                      <el-button type="primary" class="btn_login" @click="onTips" v-if="checked == false">登录</el-button>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="验证码登录" name="3">
                  <div class="popup_login_box_tabs_three">
                    <div class="popup_login_box_tabs_three_form">
                      <p class="popup_login_box_tabs_three_item">
                        <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" v-model="mobile" maxlength="11"></el-input>
                      </p>
                      <p class="popup_login_box_tabs_three_item">
                        <el-input placeholder="请输入验证码" maxlength="4" :disabled="countdown != '获取验证码' ? false : true" prefix-icon="el-icon-unlock" v-model="verificationCode" class="item_input"></el-input>
                        <el-button type="primary" @click="getCode()" class="item_button" :disabled="countdown != '获取验证码' ? true : false">{{ countdown }}</el-button>
                      </p>
                      <el-button type="primary" class="btn_login" @click="login(2)" v-if="checked == true">登录</el-button>
                      <el-button type="primary" class="btn_login" @click="onTips" v-if="checked == false">登录</el-button>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div class="agreement">
                <div class="agreement_left">
                  <el-checkbox v-model="checked" @change="checkedChange">已阅读并同意</el-checkbox>
                  <span class="c-5F7CFD" @click="goAgreement()">《用户服务协议》</span> 和
                  <span class="c-5F7CFD" @click="goPolicy()">《隐私政策》</span>
                </div>
                <span class="free c-5F7CFD" @click="changeIsType('4')">免费注册</span>
              </div>
            </div>
            <div class="popup_login_box" v-on:click.stop v-if="isType == '4'">
              <div class="register_box">
                <div class="register_box_header">
                  <img src="@/assets/login/register_header.png" alt class="register_box_header_banner" />
                  <button type="button" aria-label="Close" class="el-dialog__headerbtn" @click="dialogVisible = !dialogVisible">
                    <i class="el-dialog__close el-icon el-icon-close" style="color:#fff"></i>
                  </button>
                </div>
                <div class="register_box_main">
                  <div class="register_box_main_left">
                    <img src="@/assets/login/imgpublice.png" alt />
                  </div>
                  <div class="register_box_main_right">
                    <p>
                      <img src="@/assets/login/wx_icon.png" alt />
                      <span>请使用微信扫码注册</span>
                    </p>
                    <p>
                      <img :src="qrCodeUrl" alt />
                    </p>
                  </div>
                </div>
                <div class="register_box_footer pointer" @click="changeIsType('1')">
                  <span>已注册去登录</span>
                  <img src="@/assets/login/go.png" alt @click="changeIsType('1')" />
                </div>
              </div>
            </div>
          </div>

          <div class="pinless" v-if="isShowAgreement == true && isWangJiMiMa == false">
            <div class="pinless_box">
              <div class="pinless_box_header">验证绑定手机号</div>
              <div class="pinless_box_form">
                <p class="pinless_box_item">
                  <img src="../../assets/login/phone.png" alt class="tel_icon" />
                  <el-input placeholder="请输入手机号" v-model="mobile" maxlength="11"></el-input>
                </p>
                <p class="pinless_box_item">
                  <el-input placeholder="请输入验证码" :disabled="countdown != '获取验证码' ? false : true" prefix-icon="el-icon-unlock" v-model="verificationCode" class="item_input"></el-input>
                  <el-button type="primary" @click="getCode4()" class="item_button" :disabled="countdown != '获取验证码' ? true : false">{{ countdown }}</el-button>
                </p>
                <el-button type="primary" class="btn_login" @click="login(3)" v-if="checked == true">绑定手机号</el-button>
                <el-button type="primary" class="btn_login" :disabled="true" v-if="checked == false">绑定手机号</el-button>
              </div>
            </div>
            <p class="agreement">
              <el-checkbox v-model="checked" @change="checkedChange">已阅读并同意</el-checkbox>
              <span style="color: #5F7CFD" @click="goAgreement()">《用户服务协议》</span> 和
              <span style="color: #5F7CFD;" @click="goPolicy()">《隐私政策》</span>
              <!-- <span class="free" @click="changeIsType('4')">免费注册</span> -->
            </p>
          </div>

          <div class="pinless" v-if="isShowAgreement == true && isWangJiMiMa == true">
            <div class="pinless_box">
              <div class="pinless_box_header">忘记密码</div>
              <div class="pinless_box_form">
                <p class="pinless_box_item">
                  <img src="../../assets/login/phone.png" alt class="tel_icon" />
                  <el-input placeholder="请输入手机号" v-model="wj_mobile" maxlength="11"></el-input>
                </p>
                <p class="pinless_box_item">
                  <el-input placeholder="请输入验证码" maxlength="4" :disabled="countdown != '获取验证码' ? false : true" prefix-icon="el-icon-unlock" v-model="wj_verificationCode" class="item_input"></el-input>
                  <el-button type="primary" @click="wj_getCode()" class="item_button" :disabled="countdown != '获取验证码' ? true : false">{{ countdown }}</el-button>
                </p>
                <p class="popup_login_box_tabs_two_item" style="margin-top:25px;">
                  <el-input placeholder="请输入密码" v-model="wj_password" autocomplete="new-password" maxlength="32"></el-input>
                </p>
                <p class="popup_login_box_tabs_two_item" style="margin-top:25px;">
                  <el-input placeholder="请再次输入密码" v-model="wj_newpassword" autocomplete="new-password" maxlength="32"></el-input>
                </p>
                <el-button type="primary" class="btn_login" @click="chongzhimima()" style="margin-top:30px;">重置密码</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <p>
          Copyright
          <span class="fontfam">©</span>
          2001-2022 www.saen.com All Rights Reserved 运营支持：{{ website.companyName }}
        </p>
        <p>
          <span>
            <span>京B2-20211946</span>
            <span>出版物经营许可证:京批字第直210126号</span>
            <span>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502048132" target="_blank">京公网安备11010502048132号</a>
            </span>
            <a href="//beian.miit.gov.cn/" target="_blank" rel="external nofollow">{{ website.recordNo }}</a>
          </span>
        </p>
      </div>
      <!-- <t_footer></t_footer> -->
    </div>
  </div>
</template>

<script>
import t_footer from '@/components/t_footer/index.vue'
import { mobile_password, mobile_send, get_qrcode, qrcode_status, smscode_login, mine_index, bind_mobild, wangzhanpeizhi, saomazhijiedenglu, chongzhimima } from "@/api/login";

export default {
  name: 'login',
  components: {
    t_footer,
  },
  data() {
    return {
      // logo: 'http://file.saen.com/web/PDjQG8nePR1637898575405.png',
      logo: require('../../assets/logo-new.png'),
      path: '/',
      firstTypesList: [],
      // searchText: '',
      islogin: false, //是否登录
      userInfo: {},
      hoverindex: -1,
      screenList: 0,
      arr: {},
      dialogVisible: false,
      isType: '1',
      activeName: '1',
      checked: true,// 是否阅读
      mobile: '',//手机号
      account: '',//账号
      password: '',//密码
      isShowPassword: true,//密码显示
      isRemember: false,//记住我
      verificationCode: '',//验证码
      countdown: '获取验证码',
      downtime: 60,
      qrCodeUrl: '',//二维码
      ticket: '',// 二维码状态所需参数
      isShowAgreement: false,
      openId: '',
      website: {},
      isWangJiMiMa: false,
      wj_mobile: '',
      wj_password: '',
      wj_newpassword: '',
      wj_verificationCode: '',
    }
  },
  mounted() {
    this.path = this.$store.state.path
    this.isHome = this.path == '/';
    // this.userInfo = this.$store.state.userInfo
    var setuserInfoObj = localStorage.getItem('setuserInfo');
    if (setuserInfoObj == null || setuserInfoObj == 'null') {
      localStorage.removeItem('token')
      this.islogin = false
    } else {
      console.log("jinru2");
      this.islogin = true
      this.userInfo = JSON.parse(localStorage.getItem('setuserInfo'))
    }
    this.getUserInfo()
    this.wangzhanpeizhi();
    this.getQrcode();
  },
  methods: {
    searchEnterFun(e) {
      var keyCode = window.event ? e.keyCode : e.which;
      console.log("回车搜索", keyCode, e);
      if (keyCode == 13) {
        this.getSearch()
      }
    },
    wangjimima() {
      this.isWangJiMiMa = true;
      this.isShowAgreement = true;
    },
    chongzhimima() {
      if (this.wj_password != this.wj_newpassword) {
        this.$message.error("两次输入的密码不一致");
        return false;
      }
      var obj = {
        mobile: this.wj_mobile,
        newPassword: this.wj_password,
        smsCode: this.wj_verificationCode
      }
      chongzhimima(obj).then(res => {
        console.log("重置密码");
        this.$message.success("重置密码成功");
        this.isWangJiMiMa = !this.isWangJiMiMa;
        this.isShowAgreement = false;
      }).catch(error => {
        console.log(error);
      })
    },
    wangzhanpeizhi() {
      wangzhanpeizhi().then(res => {
        console.log("网站配置");
        console.log(res);
        if (res.data.code == 0) {
          this.website = res.data.data;
        }
      }).catch(error => {
        console.log(error);
      })
    },
    tokuaijiwang() {
      var url = 'http://www.canet.com.cn/';
      window.open(url, '_blank');
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      // this.isUserInfo=false
      this.isShowAgreement = !this.isShowAgreement;
      this.isWangJiMiMa = !this.isWangJiMiMa;
      this.$emit('setTodos', !this.isUserInfo)
    },
    // 获取用户信息
    getUserInfo() {
      mine_index().then(res => {
        console.log(res);
        if (res.data.code == 0) {
          // this.$store.commit("setuserInfo", { userInfo: res.data.data });
          localStorage.setItem('setuserInfo', JSON.stringify(res.data.data))
          this.userInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
          this.$router.go(-1);
        }
      }).catch(error => {
        console.log(error);
      })
    },
    checkedChange(value) {
      console.log(value)
      this.checked = value
      // if(value==true){
      // 	this.getQrcode()
      // }
    },
    // 跳转用户协议和隐私政策
    goAgreement() {
      console.log("点击了用户协议");
      // if(this.checked==false){
      // 	let routeData = this.$router.resolve({
      // 		path: '/html', query: {} 
      // 	}); 
      // 	window.open(routeData.href, '_blank');
      // }
      let routeData = this.$router.resolve({
        path: '/html', query: { name: '服务协议' }
      });
      window.open(routeData.href, '_blank');
    },
    //隐私政策
    goPolicy() {
      console.log("点击了隐私政策");
      let routeData = this.$router.resolve({
        path: '/html', query: { name: '隐私政策' }
      });
      window.open(routeData.href, '_blank');
    },


    // 跳转页面
    topage(page) {
      this.$router.push({
        path: page,
        query: {}
      });
    },
    // 打开分类
    opensort() {
      this.isSort = !this.isSort
    },
    // 去app
    toapp() {
      this.$router.push({
        path: '/appdetail',
      });
    },

    // 搜索
    getSearch() {
      console.log("getSearch method..................");
      var that = this
      var num = 1
      if (that.page == '/ask' || that.page == '/askDetail') {
        num = 2
      } else if (that.page == '/information' || that.page == '/informationDetail') {
        num = 4
      } else if (that.page == '/datum' || that.page == '/datumDetail') {
        num = 3
      } else {
        num = 1
      }
      let routeUrl = this.$router.resolve({
        path: '/searchpage',
        query: { searchText: that.searchText, num: num }
      });
      window.open(routeUrl.href, '_blank');
      // that.$router.push({
      // 	path: '/searchpage',
      // 	query: {
      // 		searchText: that.searchText,
      // 		num:num
      // 	}
      // });
    },
    // 退出
    signOut() {
      this.islogin = false
      // this.$store.removeItem("setuserInfo")
      localStorage.removeItem("setuserInfo")
      localStorage.removeItem("token");
      this.$router.push({
        path: '/',
        query: {}
      });
    },
    // 去课程列表页
    tocourse(item) {
      console.log(item)
      this.$router.push({
        path: '/course',
        query: {
          item: JSON.stringify(item),
        }
      });
    },
    // 分类鼠标移动入
    hoverCategoryList(index) {
      this.hoverindex = index
    },

    // tab 切换
    handleClick(e) {
      console.log(e)
      this.isType = e.name
      this.activeName = e.name
      if (e.name == '1' && this.checked == true) {
        this.getQrcode()
      }
    },
    // 更变isType
    changeIsType(num) {
      console.log('12121')
      this.isType = num
    },
    // 切换是否显示密码
    cut(num) {
      if (num == 1) {
        this.isShowPassword = true
      } else {
        this.isShowPassword = false
      }
    },
    // 点击登录
    login(num) {
      if (num == 1) {
        var obj = {
          mobile: this.account,
          password: this.password
        }
        mobile_password(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            localStorage.setItem("token", res.data.data.token);
            this.islogin = true
            this.mine_index()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      } else if (num == 2) {
        var obj = {
          mobile: this.mobile,
          smsCode: this.verificationCode
        }
        smscode_login(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.islogin = true
            localStorage.setItem("token", res.data.data.token);
            this.mine_index()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      }
      else if (num == 3) {
        var obj = {
          mobile: this.mobile,
          smsCode: this.verificationCode,
          openId: this.openId
        }
        bind_mobild(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.islogin = true
            localStorage.setItem("token", res.data.data.token);
            this.mine_index()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      } else if (num == 4) {

        var obj = {
          openId: this.openId
        }
        saomazhijiedenglu(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.islogin = true
            localStorage.setItem("token", res.data.data.token);
            this.mine_index()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    onTips() {
      this.$message.error('请阅读并同意《用户服务协议》和《隐私政策》');
    },
    // 获取我的信息
    mine_index() {
      mine_index().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.userInfo = res.data.data
          this.$store.commit("setuserInfo", { userInfo: res.data.data })
          localStorage.setItem("setuserInfo", JSON.stringify(res.data.data))
          this.dialogVisible = false
          this.islogin = true
          this.isShowAgreement = false
          //跳转到首页去
          this.$router.push({
            path: '/myself/myCenter',
            query: {}
          });


        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getCode4() {
      if (this.mobile == '' || this.mobile == null || this.mobile == undefined) {
        this.$message.error('请输入正确的手机号码');
      } else {
        var obj = {
          mobile: this.mobile,
          type: 4
          // password:this.password
        }
        mobile_send(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.countDownTime()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    // 获取验证码
    getCode() {
      if (this.mobile == '' || this.mobile == null || this.mobile == undefined) {
        this.$message.error('请输入正确的手机号码');
      } else {
        var obj = {
          mobile: this.mobile,
          type: 2
          // password:this.password
        }
        mobile_send(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.countDownTime()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    // 获取验证码
    wj_getCode() {
      if (this.wj_mobile == '' || this.wj_mobile == null || this.wj_mobile == undefined) {
        this.$message.error('请输入正确的手机号码');
      } else {
        var obj = {
          mobile: this.wj_mobile,
          type: 3
          // password:this.password
        }
        mobile_send(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.countDownTime()
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    // 倒计时
    countDownTime() {
      let that = this
      let downtime = that.downtime
      setTimeout(() => {
        if (that.downtime > 0) {
          that.downtime = downtime - 1
          that.countdown = that.downtime + '秒后重试'
          that.countDownTime()
        }
        else {
          that.downtime = 60
          that.countdown = '获取验证码'
        }
      }, 1000)
    },
    // 获取二维码
    getQrcode() {
      var that = this
      get_qrcode().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          that.qrCodeUrl = res.data.data.qrCodeUrl
          that.ticket = res.data.data.ticket
          that.qrcode_status()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取二维码状态
    qrcode_status() {
      var obj = {
        ticket: this.ticket
      }
      if (this.checked == true && this.isType == '1') {
        qrcode_status(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            if (res.data.data.wxOpenId != '' && res.data.data.wxOpenId != null && res.data.data.wxOpenId != undefined) {
              this.openId = res.data.data.wxOpenId
              if (res.data.data.isRegister == 0) {
                this.isShowAgreement = true
              } else {
                // this.mine_index()
                this.login(4)
              }
              // this.mine_index()
              return false
            } else {
              setTimeout(() => {
                this.qrcode_status()
              }, 2000)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      } else if (this.isType == '4') {
        qrcode_status(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            if (res.data.data.wxOpenId != '' && res.data.data.wxOpenId != null && res.data.data.wxOpenId != undefined) {
              // this.isShowAgreement=true
              this.openId = res.data.data.wxOpenId
              if (res.data.data.isRegister == 0) {
                this.isShowAgreement = true
              } else {
                // this.mine_index()
                this.login(4)
              }
              return false
            } else {
              setTimeout(() => {
                this.qrcode_status()
              }, 2000)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
      else {
        // this.$message.warning('请阅读并同意《用户服务协议》和《隐私政策》')
        return false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
/* flex */
.f,
.f-h-c,
.f-v-c,
.f-cc,
.f-column,
.f-bc {
  display: flex;
  display: -webkit-flex;
}
.f-h-c {
  justify-content: center;
}
.f-cc {
  justify-content: center;
  align-items: center;
}
.f-bc {
  justify-content: space-between;
  align-items: center;
}
.f-column {
  flex-direction: column;
}
.f-auto {
  flex: auto;
}
.head {
  min-width: 1200px;
  height: 80px;
}
.main-box {
  min-height: 460px;
  padding: 30px 0;
  /* background-color: #0c5ae7; */
  /* background: url(https://saenjiaoyu.oss-cn-beijing.aliyuncs.com/login_bg.png) no-repeat center top #0c5ae7; */
  background: url("../../assets/logo-bg.png") no-repeat center top #0c5ae7;
}
.logo {
  height: 50px;
  /* width:100%; */
}
.login {
  min-width: 1200px;
  overflow: auto;
}
.main {
  width: 1200px;
  display: flex;
  justify-content: flex-end;
}
.foot {
  /* margin-top:250px; */
  padding: 22px 0;
  color: #666;
  font-size: 12px;
  font-family: \5fae\8f6f\96c5\9ed1, \9ed1\4f53;
  text-align: center;
  line-height: 26px;
  background: #f6f6f6;
  border-top: 1px solid #eee;
  span {
    margin-right: 10px;
  }
}
a {
  color: #666;
  text-decoration: none;
}

.pinless {
  width: 480px;
}
.pinless_box_header {
  margin-top: 20px;
}
.popup_login_box {
  width: 480px;
  background: #fff;
  border-radius: 8px;
}
.t_header .popup_login_box_tabs_one {
  padding: 0;
}
.agreement_left {
  font-size: 13px;
  /* min-width: 300px; */
}
.c-5F7CFD {
  color: #5f7cfd;
  cursor: pointer;
}

.t_header .popup_login_box_tabs_two,
.t_header .popup_login_box_tabs_three {
  padding: 0 80px;
}
.t_header .popup_login_box .register_box_header {
  height: 90px;
}
.xuexizhongxinclass {
  cursor: pointer;
  margin-right: 20px;
  padding: 0 12px;
  height: 34px;
  background: #5f7cfd;
  border-radius: 6px;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5f7cfd !important;
  font-weight: 400;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #5f7cfd !important;
  border-color: #5f7cfd;
}

.el-dialog__body {
  padding: 0px 20px;
}
</style>