<template>
    <div class="t_footer" style="background:#2c363d">
        <div class="footer">
            <div class="footer_one">
                <img class=""  :src="website.logo2" >
                <!-- <p>{{website.companyName}}</p> -->
            </div>
            <div class="wire"></div>
            <div class="footer_two">
                <ul>
                    <li @click="xieyi('关于我们')">关于我们</li>
                    <li @click="shizililiang()">师资力量</li>
                    <li @click="xieyi('商务合作')">商务合作</li>
                   
                </ul>
                <ul>
                    <li @click="xieyi('联系方式')">联系方式</li>
                    <li @click="xieyi('服务协议')">服务协议</li>
                    <li @click="xieyi('加入我们')">加入我们</li>
                    <!-- <li @click="kuaijiwangxiao()">会计网校</li>
                    
                    <li @click="xieyi('版权声明')">版权声明</li> -->
                    
                </ul>
            </div>
            <div class="wire"></div>
            <div class="footer_three">
                <div class="footer_three_item">
                    <img :src="website.qrCode1" alt="">
                    <p>微信公众号</p>
                </div>
                <div class="footer_three_item">
                    <img :src="website.qrCode2" alt="">
                    <p>手机APP</p>
                </div>
            </div>
            <div class="wire"></div>
            <div class="footer_four">
                <div class="footer_four_item" style="margin-top: 15px;">
                    <img src="@/assets/home/tel_icon.png" alt="">
                    <span>咨询电话：</span>
                    <span>{{website.contactNumber}}</span>
                </div>
                <div class="footer_four_item">
                    <img src="@/assets/home/service_icon.png" alt="">
                    <span>在线客服：</span>
                    <span style="cursor:pointer;color:#ffffff;" @click="zixun()">点击咨询</span>
                </div>
            </div>
        </div>
        <!-- 备案信息 -->
        <div class="fileInfo">
            <p >
                <span>2001-2024</span>
                <span>www.saen.com</span>
                <span>All Rights Reserved 运营支持：{{website.companyName}}</span>
            </p>
            <p>
                <span>京B2-20211946</span>
                <span>出版物经营许可证:京批字第直210126号</span>
                <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502048132" target="_blank">京公网安备11010502048132号</a></span>
                <span @click="tonewpage" style="cursor:pointer;">{{website.recordNo}}</span>
            </p>
        </div>
    </div>
</template>

<script>
import {wangzhanpeizhi } from "@/api/login";
export default {
    name: 't_footer',
    data(){
        return{
            courseCategoryList:[],//课程类目
            examTyopeList:[],//考试类型 二级类目
            website:{}
        }
    },
    mounted(){
        this.getCourseCategories()
        this.secondaryCategory()
        this.wangzhanpeizhi();
    },
    methods:{
        xieyi(name){
            let routeData = this.$router.resolve({
                path: '/html', query: {name:name} 
            }); 
            window.open(routeData.href, '_blank');
        },
        shizililiang(){
            var url = 'http://www.canet.com.cn/teacher/'; 
			window.open(url, '_blank');
            // let routeUrl = 
            //this.$router.resolve({
            //     path: '/teacherList',
            //     query: {
            //     }
            // });
            // window.open(routeUrl.href, '_blank');
        },
        kuaijiwangxiao(){
            var url = 'http://www.canet.com.cn/'; 
			window.open(url, '_blank');
        },
        zixun(){
            var url='https://tb.53kf.com/code/client/10093167/1';
            window.open(url, '_blank');
        },
        wangzhanpeizhi(){
            wangzhanpeizhi().then(res=>{
                console.log("网站配置");
                if(res.data.code == 0){
                    this.website = res.data.data;
                }
            }).catch(error=>{
                console.log(error);
            })
        },
        // 跳转页面
        topage(page){
            this.$router.push({
                path: page,
                query: {}
            });
        },
        // 课程目录列表
        getCourseCategories(){
            this.courseCategoryList = this.$store.state.courseCategoryList
        },
        // 获取考试类型
        secondaryCategory(){
            this.examTyopeList = this.$store.state.examTyopeList
        },
        // 去备案号
        tonewpage(){
            window.open('https://beian.miit.gov.cn/', '_blank');
        }
    },

}
</script>

<style>
    ul li{
        cursor: pointer;
    }
</style>