import $axios from './index'

//手机号码和密码登录
export function mobile_password(data){
	const url = '/api/pc/v1/mobile/password/login';
	return $axios.post(url,data);
}
//PC验证码登录
export function smscode_login(data){
	const url = '/api/pc/v1/smscode/login';
	return $axios.post(url,data);
}
//用户名和密码登录
export function username_password(data){
	const url = '/api/frame/v1/pc/username/password/login';
	return $axios.post(url,data);
}
//发送短信验证码
export function mobile_send(data){
	// /api/frame/v1/common/smscode/send
	// /api/frame/v1/common/pc/smscode/send
	const url = '/api/frame/v1/common/pc/smscode/send';
	return $axios.post(url,data);
}
// 生成二维码
export function get_qrcode(data){
	const url = '/api/pc/v1/wx/get/qrcode';
	return $axios.get(url,data);
}
// 获取二维码状态
export function qrcode_status(data){
	const url = '/api/pc/v1/wx/get/qrcode/status';
	return $axios.post(url,data);
}
// 微信登录绑定
export function bind_mobild(data){
	const url = '/api/pc/v1/third/wx/login/bind/mobile';
	return $axios.post(url,data);
}
// 我的首页
export function mine_index(data){
	const url = '/api/app/v1/mine/index';
	return $axios.post(url,data);
}
//网站配置
export function wangzhanpeizhi(data){
	const url = '/api/pc/v1/index/get/web/info';
	return $axios.post(url,data);
}
export function saomazhijiedenglu(data){
	const url = '/api/pc/v1/third/wx/login';
	return $axios.post(url,data);
}
export function chongzhimima(data){
	const url = '/api/frame/v1/customer/forgetpassword/setnewpassword	';
	return $axios.post(url,data);
}
